body {
  margin: 0;
  padding: 0;
  color: #cfd0d5;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.product-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  padding: 20px;
}

/* Scoped styles for card-xyz */
.card-xyz-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
}

.card-xyz {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  border: 3px solid #3f51b5;
  overflow: hidden;
}

.card-xyz:hover {
  transform: translateY(-10px);
}

.card-xyz img {
  width: 100%;
  height: auto;
  border-bottom: 3px solid #3f51b5;
  // border-radius: 10px;
  // border-bottom-left-radius: 0px;
  // border-bottom-right-radius: 0px;
}

.card-xyz-content {
  padding: 15px;
  text-align: left;
}

.card-xyz-content h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.card-xyz-content p {
  font-size: 1rem;
  color: #777;
  margin-bottom: 15px;
}

.card-xyz-btn {
  display: inline-block;
  padding: 10px 15px;
  background: #3f51b5;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.card-xyz-btn:hover {
  background: #0056b3;
}

.font-bold {
  font-weight: bold;
}
