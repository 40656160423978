/* Variables
================================== */
$breakpoint: 500px;
$bw: 0.5px; // border width

/* Tables
================================== */
.Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
}
.Rtable-row {
  border-bottom: 1px solid #4f5163aa;
  margin: 25px;
}
.Rtable-cell {
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%; // Default to full width
  padding: 0.8em 1.2em;
  overflow: hidden; // Or flex might break
  list-style: none;
  h1 {
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    color: hsla(0, 0%, 100%, 0.5) !important;
  }
}

/* Table column sizing
================================== */
.Rtable--2cols {
  .Rtable-cell {
    width: 45%;
  }
}
.Rtable--3cols {
  .Rtable-cell {
    width: 33.33%;
  }
}
.Rtable--4cols {
  .Rtable-cell {
    width: 25%;
  }
}
.Rtable--5cols {
  .Rtable-cell {
    width: 16%;
  }
}

/* Responsive
==================================== */
@media all and (max-width: $breakpoint) {
  .Rtable--collapse {
    display: block;
    > .Rtable-cell {
      width: 100% !important;
    }
    > .Rtable-cell--foot {
      margin-bottom: 1em;
    }
  }
}

/* Non-Flex modernizer fallback
==================================== */

.no-flexbox .Rtable {
  display: block;
  > .Rtable-cell {
    width: 100%;
  }
  > .Rtable-cell--foot {
    margin-bottom: 1em;
  }
}
