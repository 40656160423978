/* main container */

.loginContainer {
  display: flex;
  width: 100vw;
  max-width: 100vw;
  /* font-family: 'Trebuchet MS', sans-serif; */
  font-family: loginPageFont;
}

::placeholder {
  color: #909090;
}
/* left side */
.staticContentWrapper {
  position: relative;
  min-height: 100vh;
  background: #ffffff;
  width: 50%;
  padding: 2.5em 3em 0 4em;
}
.bubbles.bubblesTop {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.bubbles.bubblesBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1) translateX(25%);
}
.bubbles img {
  height: 9em;
}
.loginContainer {
  font-size: 0.9vw;
}
.staticContentHeader {
  position: relative;
  z-index: 2;
  font-size: 2em;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
  color: #0f0e37;
}
.staticContentBody {
  position: relative;
  z-index: 2;
}

.listItemWrapper {
  padding-inline-start: 1em;
}
.listItem {
  font-size: 1.2em;
  height: 2.5em;
  display: flex;
  align-items: center;
  color: #1d1d1d;
}
li.listItem::before {
  content: '\2022';
  color: #0066fc;
  font-weight: bold;
  display: inline-block;
  width: 0.8em;
  font-size: 1.5em;
  margin-left: -0.4em;
  margin-right: 0.5em;
}

/* Login Container */

.loginWrapper {
  min-height: 100vh;
  background-color: #dfe7f9;
  flex: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9vw;
}

img[alt='vector_background_top'],
img[alt='vector_background_right'],
img[alt='vector_background_bottom'] {
  z-index: 0;
}
img[alt='vector_background_top'] {
  position: absolute;
  height: 25em !important;
}

.loginContainerWrapper {
  position: relative;
  width: 25em;
  z-index: 2;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
}
.loginContainerHeader {
  height: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000946;
}
.loginContainerHeader img {
  height: 4.5em;
}

.loginContainerBody {
  padding: 0px 2em;
  min-height: 18em;
  letter-spacing: 0.01em;
  padding-bottom: 3em;
  padding-top: 1.5em;
  z-index: 2;
  color: #434343;
  border-bottom: 1px solid #d4d4d4;
  border-left: 1px solid #d4d4d4;
  border-right: 1px solid #d4d4d4;
}

.loginContainerBodyCentered {
  width: 100%;
}
.inputRow {
  width: 100%;
  margin-bottom: 1em;
}
.inputRowLable {
  font-family: 'loginPageFont';
  font-size: 1em;
  line-height: 1em;
  margin-bottom: 0.5em;
  display: flex;
  align-items: baseline;
  color: #434343;
  font-weight: 600;
}
.inputRowLable img {
  margin-left: 0.5em;
  height: 1em;
  align-self: baseline;
}
.inputRowLable img[alt='mail_icon'],
.inputRowLable img[alt='lock_icon'] {
  transform: translateY(2px);
}
.clientInputWrapper {
  position: relative;
  height: 3em;
  overflow: hidden;
}
/* client's input overlay */
.inputOverlay {
  position: absolute;
  z-index: 3;
  padding: 0 1em;
  color: transparent;
  pointer-events: none;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.2em;
  line-height: 1.2em;
  letter-spacing: 0.05em;
  border: 1px transparent #fff;
}
.inputOverlay:after {
  color: rgba(var(--sk_primary_foreground, 29, 28, 29), 1);
  font-weight: 700;
}
.inputOverlay:after {
  font-weight: bold;
  height: 100%;
  display: flex;
  align-items: center;
  color: #0066fc;
  content: attr(data-overlay-suffix);
  background: transparent;
  margin-left: 0.05rem;
}

.inputRowComponent {
  padding: 0 1em;
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 0;
  background-color: #f3f3f3;
  /* font-family: 'Trebuchet MS', sans-serif; */
  font-family: loginPageFont;
  letter-spacing: 0.05em;
  font-size: 1.2em;
  line-height: 1.2em;
}

.inputRowComponent:focus-visible {
  outline: none;
  border: 1px solid #434343;
}

.inputRowComponent:-webkit-input-placeholder {
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.05em;
  color: #909090;
}
.actionButton {
  /* font-family: 'Titillium Web', sans-serif; */
  font-family: buttonsFont;
  width: 100%;
  background: #fdbd32;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  cursor: pointer;
  border-radius: 3px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000847;
  margin-top: 1em;
  font-size: 1.2em;
  line-height: 1.2em;
  height: 2.5em;
}
.actionButton:disabled {
  background: rgba(253, 189, 50, 0.8);
  color: rgba(0, 8, 71, 0.5);
  cursor: not-allowed;
}
.errorLable {
  color: crimson;
}
.confirmPasswordInput {
  background-color: #f3f3f3;
}
.confirmPasswordInput input:focus-visible {
  border: none;
}
.confirmPasswordInput:focus-within {
  border: 1px solid #434343;
}
.ssoDeviderWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  height: 1em;
}
.ssoDevider {
  background-color: #aeaeae;
  height: 1px;
  z-index: 1;
  width: 100%;
}
.ssoDeviderText {
  position: absolute;
  background-color: #fff;
  color: #a9a9a9;
  display: flex;
  justify-content: center;
  padding: 0px 0.9em;
  z-index: 2;
  font-size: 1.1em;
}

.login-loader div {
  height: 1.2em !important;
  width: 1.2em !important;
}
.forgotPasswordRow {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
  transform: translateY(-0.5em);
}
.forgotPasswordRow a {
  font-size: 1em;
  color: #3b85a6;
}

.animationWrapper {
  padding: 0 3em;
  height: 35em;
  padding-left: 20%;
  font-size: 1.5vh;
  padding-top: 5%;
}
.animationWrapperCentered {
  width: 25em;
  position: relative;
  z-index: 3;
}
.animationRow1 {
  display: flex;
  align-items: center;
  padding: 0px 0px;
  height: 12em;
}
.animationRow2 {
  position: absolute;
  z-index: 3;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0px 1em;
  transform: translateY(-2em);
}

.analyticsIconWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.analyticsIcon {
  margin-top: 2em;
  width: 25em;
}
.animationRow3 {
  position: absolute;
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 22em;
  height: 11em;
  transform: translateX(1.5em) translateY(2.9em);
}

.imageIcons {
  position: absolute;
}
.shelfIcon {
  top: 0;
  height: 13em;
}

.manIconWrapper {
  position: absolute;
  height: 100px;
  z-index: 0;
}
.manIcon {
  height: 13em;
  transform: translateX(-2.8em) translateY(10em);
}

.mobileViewHeader {
  width: 90%;
  display: none;
  margin-bottom: 1.5em;
  font-size: 3vh;
  text-align: center;
  font-weight: bold;
  color: #0f0e37;
}

.forgotPassowrdWrapper {
  padding-top: 35px;
}

.loginBackButton img {
  height: 2em;

  cursor: pointer;
}
.forgotPasswordInputRow {
  margin-top: 20px;
}

.forgotPassowrdHeadings h2 {
  /* font-family: 'Trebuchet MS', sans-serif !important; */
  font-family: buttonsFont;
  margin-bottom: 1em;
  font-size: 1.2em;
  color: #434343;
}
.forgotPassowrdHeadings p {
  font-style: italic !important;
  font-size: 0.9em;
  margin-bottom: 0.7em;
  /* font-family: 'Trebuchet MS', sans-serif !important; */
  font-family: buttonsFont;
  color: #0045e5;
}
.forgotPassowrdHeadings h3 {
  /* font-family: 'Trebuchet MS', sans-serif !important; */
  font-family: buttonsFont;
  font-size: 1.1em;
  color: #434343;
}
.loginBackButton {
  margin-bottom: 0.7em;
}
.resetPasswordWrapper {
  min-height: 25em;
}
.ssoContainer {
  text-align: center;
}
.loginContainerLable {
  margin-top: 3em;
  font-size: 1.1em;
}
@media (max-width: 1500px) {
  .loginContainer {
    font-size: 0.9vw;
  }
}
@media (max-width: 1366px) {
}
@media (max-width: 1078px) {
}
@media (orientation: portrait) and (max-width: 1080px) {
  .loginContainer {
    font-size: 1.2vw;
  }
  .loginWrapper {
    font-size: 1.5vw;
  }
  .listItem {
    font-size: 1.5em;
    margin-top: 1em;
  }
  .animationWrapper {
    font-size: 1.3vw;
    padding-top: 10%;
    padding-left: 10%;
  }
}
@media (max-width: 900px) {
  .loginWrapper {
    flex: 100%;
    justify-content: initial;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    /* border: 1px solid red; */
    padding-top: 50px;
    /* align-items: center; */
  }
  .staticContentWrapper {
    display: none;
  }
  .mobileViewHeader {
    /* position: absolute; */
    z-index: 2;
    display: block;
    /* top: 80px; */
    font-size: 18px;
  }
  .loginContainer {
    font-size: 1vh;
  }
  .loginWrapper {
    font-size: 11px;
  }
  img[alt='vector_background_top'] {
    display: none;
  }
  img[alt='vector_background_right'] {
    height: 180px !important;
  }
  img[alt='vector_background_bottom'] {
    position: fixed;
    height: 150px !important;
    bottom: 100px;
  }
}
@media (orientation: landscape) and (max-width: 900px) {
  .mobileViewHeader {
    /* font-size: 2vw;
    top: 9%; */
  }
  .loginContainer {
    font-size: 1.5vh;
  }
  .loginWrapper {
    font-size: 10px;
    /* padding-top: 14%; */
  }
  img[alt='vector_background_top'] {
    width: 50% !important;
  }
  img[alt='vector_background_right'] {
    width: 50% !important;
    position: absolute;
    right: 0 !important;
  }
  img[alt='vector_background_bottom'] {
    width: 40% !important;
    left: 0;
    bottom: 0;
  }
}
/* @media (orientation: landscape) and (max-width: 550px) {
  .mobileViewHeader { */
/* position: unset; */
/* font-size: 18px; */
/* top: 9%; */
/* }
} */
@media (max-width: 360px) {
  /* .loginWrapper {
    padding-top: 150px;
  } */
  .mobileViewHeader {
    /* position: absolute; */
    z-index: 2;
    display: block;
    top: 50px;
    font-size: 18px;
  }
}
