.line-wrapper {
  .recharts-legend-item-text {
    text-transform: uppercase;
    font-size: 10px !important;
    font-weight: 500;
    color: rgb(113, 113, 121);
  }
  .recharts-label {
    font-weight: 500;
    fill: #888893;
  }
}
