.image-logo {
  padding: 0 1rem;
  height: 33px;
  width: 63px;
  padding-top: 7px;
  padding-right: 15px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #06060f;
}
.DashboardPanel-container-246::-webkit-scrollbar,
[class*='MuiDrawer-paper']::-webkit-scrollbar,
[class*='DashboardPanel-container']::-webkit-scrollbar,
[class*='SideBarNew-drawerPaper']::-webkit-scrollbar {
  display: none !important;
}
html,
[class*='MuiDrawer-paper'] {
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none !important; /* Firefox */
}
