.rsbc-switch-button {
  position: relative;
  line-height: 100%;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.rsbc-switch-button label {
  cursor: pointer;
  color: hsla(0, 0%, 100%, 0.6);
}
.rsbc-switch-button.disabled label {
  cursor: not-allowed;
}
.rsbc-switch-button input[type='checkbox'] {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.rsbc-switch-button > label:first-child {
  display: inline-block;
  margin: 0 6px 0 0;
}
.rsbc-switch-button label + label {
  margin-left: 6px;
}
.rsbc-switch-button input[type='checkbox'] + label {
  display: inline-block;
  position: relative;
  margin: 0;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.rsbc-switch-button.rsbc-switch-button-flat-round
  input[type='checkbox']
  + label {
  padding: 2px;
  width: 48px;
  height: 24px;
  vertical-align: middle;
  background-color: #579fcb;
  border-radius: 60px;
  -webkit-transition: background 0.4s;
  transition: background 0.4s;
}
.rsbc-switch-button.rsbc-switch-button-flat-round.disabled
  input[type='checkbox']
  + label {
  background-color: #eee;
}
.rsbc-switch-button.rsbc-switch-button-flat-round:hover
  input[type='checkbox']
  + label {
  background-color: #579fcb;
}
.rsbc-switch-button.rsbc-switch-button-flat-round.disabled:hover
  input[type='checkbox']
  + label {
  background-color: #eee;
}
.rsbc-switch-button.rsbc-switch-button-flat-round
  input[type='checkbox']
  + label:after,
.rsbc-switch-button.rsbc-switch-button-flat-round
  input[type='checkbox']
  + label:before {
  display: inline-block;
  position: absolute;
  content: '';
}
.rsbc-switch-button.rsbc-switch-button-flat-round
  input[type='checkbox']
  + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #1e1e2b;
  border-radius: 60px;
  -webkit-transition: background 0.4s;
  transition: background 0.4s;
}
.rsbc-switch-button.rsbc-switch-button-flat-round
  input[type='checkbox']
  + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 16px;
  background-color: #579fcb;
  border-radius: 52px;
  -webkit-transition: margin 0.4s, background 0.4s;
  transition: margin 0.4s, background 0.4s;
}
.rsbc-switch-button.rsbc-switch-button-flat-round.disabled
  input[type='checkbox']
  + label:after {
  background-color: #d5d5d5;
}
.rsbc-switch-button.rsbc-switch-button-flat-round:hover
  input[type='checkbox']:checked
  + label {
  background-color: #579fcb;
}
.rsbc-switch-button.rsbc-switch-button-flat-round
  input[type='checkbox']:checked
  + label {
  background-color: #579fcb;
}
.rsbc-switch-button.rsbc-switch-button-flat-round.disabled
  input[type='checkbox']:checked
  + label {
  background-color: #eee;
}
.rsbc-switch-button.rsbc-switch-button-flat-round
  input[type='checkbox']:checked
  + label:after {
  margin-left: 24px;
  background-color: #3887b7;
}

.rsbc-switch-button.rsbc-switch-button-flat-round.disabled
  input[type='checkbox']:checked
  + label:after {
  margin-left: 24px;
  background-color: #d5d5d5;
}
.rsbc-switch-button.rsbc-switch-button.rsbc-switch-button-flat-round.rsbc-mode-select:hover
  input[type='checkbox']:checked
  + label,
.rsbc-switch-button.rsbc-switch-button.rsbc-switch-button-flat-round.rsbc-mode-select
  input[type='checkbox']
  + label,
.rsbc-switch-button.rsbc-switch-button.rsbc-switch-button-flat-round.rsbc-mode-select
  input[type='checkbox']
  + label:after {
  background-color: #579fcb !important;
}
.rsbc-switch-button.rsbc-switch-button-flat-square
  input[type='checkbox']
  + label {
  padding: 2px;
  width: 48px;
  height: 24px;
  vertical-align: middle;
  background-color: #579fcb;
  border-radius: 3px;
  -webkit-transition: background 0.4s;
  transition: background 0.4s;
}
.rsbc-switch-button.rsbc-switch-button-flat-square.disabled
  input[type='checkbox']
  + label {
  background-color: #eee;
}
.rsbc-switch-button.rsbc-switch-button-flat-square:hover
  input[type='checkbox']
  + label {
  background-color: #579fcb;
}
.rsbc-switch-button.rsbc-switch-button-flat-square.disabled:hover
  input[type='checkbox']
  + label {
  background-color: #eee;
}
.rsbc-switch-button.rsbc-switch-button-flat-square
  input[type='checkbox']
  + label:after,
.rsbc-switch-button.rsbc-switch-button-flat-square
  input[type='checkbox']
  + label:before {
  display: inline-block;
  position: absolute;
  content: '';
}
.rsbc-switch-button.rsbc-switch-button-flat-square
  input[type='checkbox']
  + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #1e1e2b;
  border-radius: 2px;
  -webkit-transition: background 0.4s;
  transition: background 0.4s;
}
.rsbc-switch-button.rsbc-switch-button-flat-square
  input[type='checkbox']
  + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 16px;
  background-color: #579fcb;
  border-radius: 2px;
  -webkit-transition: margin 0.4s, background 0.4s;
  transition: margin 0.4s, background 0.4s;
}
.rsbc-switch-button.rsbc-switch-button-flat-square.disabled
  input[type='checkbox']
  + label:after {
  background-color: #d5d5d5;
}
.rsbc-switch-button.rsbc-switch-button-flat-square:hover
  input[type='checkbox']:checked
  + label {
  background-color: #579fcb;
}
.rsbc-switch-button.rsbc-switch-button-flat-square
  input[type='checkbox']:checked
  + label {
  background-color: #579fcb;
}
.rsbc-switch-button.rsbc-switch-button-flat-square.disabled
  input[type='checkbox']:checked
  + label {
  background-color: #eee;
}
.rsbc-switch-button.rsbc-switch-button-flat-square
  input[type='checkbox']:checked
  + label:after {
  margin-left: 24px;
  background-color: #3887b7;
}
.rsbc-switch-button.rsbc-switch-button-flat-square.disabled
  input[type='checkbox']:checked
  + label:after {
  margin-left: 24px;
  background-color: #d5d5d5;
}
.rsbc-switch-button.rsbc-switch-button.rsbc-switch-button-flat-square.rsbc-mode-select:hover
  input[type='checkbox']:checked
  + label,
.rsbc-switch-button.rsbc-switch-button.rsbc-switch-button-flat-square.rsbc-mode-select
  input[type='checkbox']
  + label,
.rsbc-switch-button.rsbc-switch-button.rsbc-switch-button-flat-square.rsbc-mode-select
  input[type='checkbox']
  + label:after {
  background-color: #579fcb !important;
}
