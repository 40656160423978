.MuiInput-underline:before {
  border-bottom: 0 !important;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 0 !important;
}
.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-size: 15px;
}

.survey-list {
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 100px;
}

.survey-title {
  margin-top: 40px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}

div.survey-list > div:nth-child(1) {
  margin-top: 20px;
}

.survey-item {
  background: #FFFFFF;
  border: 1px solid #CBD5E1;
  padding: 20px;
  margin-top: 10px;
}

.survey-answer {
  margin: 10px;
}

.answer-value {
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid #000000;
  margin-top: 5px;
  min-height: 26px;
}

.photo-item {
  position: relative;
}

.survey-photos {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 20px;
  padding: 10px;
}

.survey-photos img {
  width: 100px;
  height: 100px;
  border: 2px solid #A7A7A7;
}