.recharts-rectangle:hover {
  fill: transparent;
}
.recharts-legend-item-text {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  color: rgb(113, 113, 121);
}
.recharts-cartesian-axis-ticks {
  font-size: 12px !important;
  text-transform: uppercase;
  font-weight: 500;
}
.recharts-layer .recharts-bar {
  height: 1000px;
  overflow: auto;
}
g.recharts-brush {
  rect:nth-child(1) {
    border-radius: 50%;
    height: 10px;
    fill: transparent;
    stroke: transparent;
    display: none;
    rx: 10;
    ry: 10;
  }
}
.recharts-brush-slide {
  // user-select: none;
  // transform: rotate(90deg) translate(29%, -211%);
  // transform-origin: center;
  cursor: pointer !important;
  height: 10px;
  fill: #414142;
  fill-opacity: 1;
  border-radius: 50%;
}
.recharts-brush-traveller {
  display: none;
}
.recharts-brush-texts {
  display: none;
}

@media screen and (max-width: 480px) {
  .recharts-cartesian-axis-ticks {
    font-size: 9px;
  }
}
